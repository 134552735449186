<template>
    <Alert v-for="(a, index) in alert" :key="a" :data="a" @closeAlert="closeAlert(index)"/>
    <TopNavigation step="0" title="Team permission" icon="user-shield" />

    <div class="mainBox mainBox55 border shadow-sm">
        <div class="row">
            <!-- <div class="col-1">
                <Popper class="popperDark" arrow hover content="Back">
                    <fa icon="angle-left" v-if="!showEditMode" size="lg" class="me-3 isLink" @click="$router.push({ path: '/team/list' })" />
                </Popper>
            </div> -->
            <div class="col-12 mb-2">
                <table width="100%" border="0">
                    <tr>
                        <td width="30px">
                            <Popper class="popperDark" arrow hover content="Back to Team">
                                <fa icon="angle-left" v-if="!showEditMode" size="lg" class="isLink" @click="$router.push({ path: '/team/list' })" />
                            </Popper>
                        </td>
                        <td>
                            <div class="input-group">
                                <span class="input-group-text"><fa icon="users" /></span>
                                <select v-model="teamId" class="form-select" @change="changeTeam">
                                    <option :value="t.teamId" v-for="t in arrTeam" :key="t.teamId">{{ t.teamName }}</option>
                                </select>
                            </div>
                        </td>
                    </tr>
                </table>
            </div>
            <div class="col-12">
                <table class="table table-hover">
                    <thead>
                        <tr class="fw-bold">
                            <td>
                                <div class="row">
                                    <div class="col-12 col-lg-3">Category</div>
                                    <div class="col-12 col-lg-4">Menu</div>
                                    <div class="col-4 col-lg-2"></div>
                                    <div class="col-4 col-lg-2"></div>
                                    <div class="col-4 col-lg-1"></div>
                                </div>
                            </td>
                        </tr>
                    </thead>   
                    <tbody v-for="(item, itemIdx) in arrItem" :key="item">
                        <tr v-for="(menu, menuIdx) in item.menus" :key="menu"> 
                            <td>
                                <div class="row">
                                    <div class="col-12 col-lg-3"><div v-if="menuIdx === 0">{{item.category}}</div></div>
                                    <div class="col-12 col-lg-4">{{menu.name}}</div>
                                    <div class="col-4 col-lg-2 text-end text-grey">{{menu.totalTeam}} 
                                        <Popper class="popperDark" arrow hover content="Team"><fa icon="users" class="mx-1"/></Popper> 
                                        <span class="ps-2 isLink" data-bs-toggle="modal" data-bs-target="#mdlTeam" @click="menuName = menu.name">view</span>
                                    </div>
                                    <div class="col-4 col-lg-2 text-end text-grey">{{menu.totalMember}} 
                                        <Popper class="popperDark" arrow hover content="Member"><fa icon="user" class="mx-1"/></Popper> 
                                        <span class="ps-2 isLink" data-bs-toggle="modal" data-bs-target="#mdlMember" @click="menuName = menu.name">view</span>
                                    </div>
                                    <div class="col-4 col-lg-1 text-end pe-3">
                                        <input type="checkbox" v-model="a" class="form-check-input" />
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>    
                </table>
            </div>

            <div class="col-12 pt-3 text-center">
                <button class="btn btn-secondary me-2" @click="$router.push({ path: '/team/list' })">
                    <fa icon="angle-left" size="lg" class="me-2" />Back
                </button>
                <button class="btn btn-secondary">
                    <fa icon="rotate" size="lg" class="me-2" />Update
                </button>
            </div>
        </div>
    </div>

    <!-- Modal --> 
    <div
        class="modal fade"
        id="mdlTeam"
        tabindex="-1"
        aria-labelledby="Team"
        aria-hidden="true"
    >
        <div class="modal-dialog modal-dialog-centered text-dark">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 class="modal-title">Team :: {{menuName}}</h5>
                    <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="mb-2">
                        <div class="input-group">
                            <span class="input-group-text"><fa icon="search" /></span>
                            <input type="text" v-model="filterName" class="form-control" placeholder="Search" @dblclick="delfilterName">
                        </div>
                    </div>

                    <div class="table-responsive" style="height: 300px">
                        <table class="table table-hover" border="0">
                            <tbody v-if="arrTeamMbr">
                                <tr v-for="(t, tIdx) in arrTeamMbr" :key="t" data-bs-dismiss="modal">
                                    <td v-if="t" width="50px" class="align-middle">
                                        <Avatar :key="tIdx" isUser="true" avatar="" displayName="" :isVerified="t.isVerified" width="30" height="30" />
                                    </td>
                                    <td class="align-middle">
                                        <div class="fw-bold isLink">
                                            <router-link :to="'/team/member/' + t.userId">
                                                <span>{{t.lastName}} {{t.firstName}}</span>
                                                <span class="text-grey"><fa icon="at" class="mx-1"/>{{ t.displayName }}</span>
                                            </router-link>
                                        </div>

                                        <div class="small text-grey">{{ t.userId }}</div>
                                    </td>
                                </tr>
                               
                            </tbody>
                        </table>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
    <!-- Modal End -->

    <!-- Modal --> 
    <div
        class="modal fade"
        id="mdlMember"
        tabindex="-1"
        aria-labelledby="TeamMbr"
        aria-hidden="true"
    >
        <div class="modal-dialog modal-dialog-centered text-dark">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 class="modal-title">Member :: {{menuName}}</h5>
                    <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="mb-2">
                        <div class="input-group">
                            <span class="input-group-text"><fa icon="search" /></span>
                            <input type="text" v-model="filterName" class="form-control" placeholder="Search" @dblclick="delfilterName">
                        </div>
                    </div>

                    <div class="table-responsive" style="height: 300px">
                        <table class="table table-hover" border="0">
                            <tbody v-if="arrTeamMbr">
                                <tr v-for="(t, tIdx) in arrTeamMbr" :key="t" data-bs-dismiss="modal">
                                    <td v-if="t" width="50px" class="align-middle">
                                        <Avatar :key="tIdx" isUser="true" avatar="" displayName="" :isVerified="t.isVerified" width="30" height="30" />
                                    </td>
                                    <td class="align-middle">
                                        <div class="fw-bold isLink">
                                            <router-link :to="'/team/member/' + t.userId">
                                                <span>{{t.lastName}} {{t.firstName}}</span>
                                                <span class="text-grey"><fa icon="at" class="mx-1"/>{{ t.displayName }}</span>
                                            </router-link>
                                        </div>

                                        <div class="small text-grey">{{ t.userId }}</div>
                                    </td>
                                </tr>
                               
                            </tbody>
                        </table>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
    <!-- Modal End -->

    

</template>

<script>
import { ref, inject, onMounted } from 'vue'
import TopNavigation from '@/components/TopNavigation.vue'
import Alert from '@/components/Alert.vue'
import { useRouter, useRoute } from 'vue-router'
import Popper from 'vue3-popper'
import funcs from '@/functions/function'
import { useStore } from '@/stores/store'
import Avatar from '@/components/Avatar.vue'

export default {
    name: 'TeamPermission',
    components: { Alert, TopNavigation, Popper, Avatar },
    setup () {
        const axios = inject('axios')
        const alert = ref([])
        const route = useRoute()
        const router = useRouter()
        const func = funcs
        const store = useStore()

        const arrItem = ref([])
        const arrTeam = ref([])
        const teamId = ref(null)
        const menuName = ref(null)

        const closeAlert = (index) => {
            alert.value.splice(index, 1)
        }

        const changeTeam = (event) => {
            console.info('select', event.target.value)
            router.push({ path: '/team/' + event.target.value + '/permission' })
        }

        onMounted(() => {

            teamId.value = route.params.teamId

            arrTeam.value = [
                {
                    teamId: '1001',
                    teamName: 'admin',
                    isDefault: true,
                    total: 1
                },
                {
                    teamId: '1002',
                    teamName: 'developer',
                    isDefault: false,
                    total: 35
                },
                {
                    teamId: '1003',
                    teamName: 'customer service',
                    isDefault: false,
                    total: 2
                }
            ]

            arrItem.value = [
                {
                    category: 'MAIN',
                    menus: [
                        {
                            name:'Dashboard',
                            totalMember: 25,
                            totalTeam: 20
                        },
                        {
                            name:'Rack',
                            totalMember: 12,
                            totalTeam: 3
                        },
                        {
                            name:'Contact',
                            totalMember: 20,
                            totalTeam: 5
                        },
                        {
                            name:'Team',
                            totalMember: 15,
                            totalTeam: 3
                        }
                    ]
                },
                {
                    category: 'TOOL',
                    menus: [
                        {
                            name:'API',
                            totalMember: 13,
                            totalTeam: 6
                        },
                        {
                            name:'QR SignOn',
                            totalMember: 8,
                            totalTeam: 3
                        },
                        {
                            name:'Form',
                            totalMember: 20,
                            totalTeam: 5
                        },
                        {
                            name:'Workflow',
                            totalMember: 7,
                            totalTeam: 3
                        }
                    ]
                },
                {
                    category: 'BACK OFFICE',
                    menus: [
                        {
                            name:'Configuration',
                            totalMember: 10,
                            totalTeam: 5
                        },
                        {
                            name:'Subscriber',
                            totalMember: 6,
                            totalTeam: 2
                        },
                        {
                            name:'KYC Verification',
                            totalMember: 8,
                            totalTeam: 3
                        }
                    ]
                }
            ]

        })

        return {
            alert, closeAlert, route, router, func, store, 
            arrItem, arrTeam, teamId, changeTeam, menuName
        }

    }
}
</script>

<style>

</style>